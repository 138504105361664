body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.total-counter {
  position: absolute;
  bottom: 24px;
  right: 24px;
  width: 120px;
  height: 1.4rem;
  background-color: #b7afc0;
  text-align: right;
  padding: 2px 12px;
  font-weight: bold;
}
